import { StyleSheet, Platform, Dimensions } from 'react-native-web';
import color from './color';
import { fonts } from "./Fonts";

export const { height, width } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Platform.OS === 'ios' ? '#F0F0F0' : '#f3f3f3',
  },
  whiteContainer: {
    flex: 1,
    backgroundColor: '#fff',
  },
  containerWhite: {
    flex: 1,
    backgroundColor: '#fff',
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  rowDirection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomActions: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    backgroundColor: color.primaryColor,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bottomAction: {
    maxHeight: 50,
    paddingVertical: 12,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBottomAction: {
    width: 24,
    height: 24,
    tintColor: '#fff',
  },
  textBottomAction: {
    fontFamily: fonts.QuicksandBold,
    fontSize: 16,
    marginLeft: 10,
    color: '#fff',
  },
  textBottomInfo: {
    fontSize: 12,
    color: '#fff',
  },
  divider: {
    backgroundColor: color.grayContainer,
    height: 1,
  },
  dropShadow: {
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
  },
});
