import React from "react";
import { Layout } from "antd";
import {version} from '../../package.json';
const { Footer } = Layout;


const VersionComponent = () => {
    const today = new Date();
    const year = today.getFullYear();
    
  return (
    <Footer
      style={{
        borderTop: "1px",
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        textAlign: "center",
      }}
    >
      Polibras Software ©{year} - v{version}
    </Footer>
  );
};

export default VersionComponent;
