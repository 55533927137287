import { atom } from 'recoil';

const profileState = atom({
  key: 'profile',
  default: {
    company: {},
    seller: {},
    user: {},
    settings: {
      'calculation_decimal_places': 2,
      'visual_decimal_places': 2
    },
    version: {}
  },
  persistence_UNSTABLE: {
    type: 'profile'
  },
});

export default profileState;
