import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash';
import { Alert, Badge, Button, message } from 'antd'
import { Text, TouchableOpacity, View } from 'react-native-web'
import {
  CheckCircleOutlined,
  UpOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'

import { color } from '../../common'
import OrdersList from '../Orders/OrdersList'
import { getDocument } from "../../Services/Document";
import { Icon as LegacyIcon } from "@ant-design/compatible";

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 16,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: '#074885',
    fontSize: 12,
    fontWeight: '600',
  },
  iconContainer: {
    width: 35,
    height: 35,
    borderRadius: 25,
    backgroundColor: color.primaryColorLightest,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  icon: {
    width: 24,
    height: 24,
    tintColor: color.primaryColorLight,
  },
  badgeContainer: {
    width: 22,
    height: 22,
    borderRadius: 12,
    backgroundColor: color.grayContainerDark,
    justifyContent: 'center',
    alignItems: 'center',
  },
  greenBadgeContainer: {
    width: 16,
    height: 16,
    borderRadius: 12,
    backgroundColor: '#FFC400', // '#0EAF31',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBadgeCheck: {
    width: 18,
    height: 18,
    tintColor: '#fff',
  },
}

const DocumentItemExpanded = ({
  item,
  onPress,
  onClickOrder,
  onSavingOrderNumber,
  onEditingNumberOrder,
  editing,
  showModal,
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState(null);

  const getIconStatus = (item) => {
    const { status } = item;
    let checkStatus = status;

    switch (checkStatus) {
      case 'created':
        return (
          <ClockCircleOutlined style={{
            backgroundColor: '#FFC400',
            color: '#fff',
            fontSize: 20,
            borderRadius: '50%'
          }} />
        )
      case 'error':
        return (
          <CloseCircleOutlined style={{
            backgroundColor: '#f10505',
            color: '#fff',
            fontSize: 20,
            borderRadius: '50%'
          }} />
        )
      case 'processing':
        return (
          <ClockCircleOutlined style={{
            backgroundColor: '#FFC400',
            color: '#fff',
            fontSize: 20,
            borderRadius: '50%'
          }} />
        )
      case 'done':
        return (
          <CheckCircleOutlined
            style={{
              backgroundColor: '#52c41a',
              color: '#fff',
              fontSize: 20,
              borderRadius: '50%'
            }}
          />
        )
      default:
        return null;
    }
  }

  const getFileName = () => {
    const url = item.document_file;
    return url ? url.substring(url.lastIndexOf('/') + 1) : item.identifier;
  }

  const loadDocument = useCallback(async () => {
    try {
      setIsLoading(true);
      const doc = await getDocument(item.id);
      setDocument({ ...doc });
    } catch (error) {
      message.error('Não foi possível carregar o documento')

    } finally {
      setIsLoading(false);
    }
  }, [item])

  useEffect(() => {
    loadDocument();
  }, [item, loadDocument]);

  const onRefresh = () => {
    loadDocument();
  }

  return (
    <>
      <TouchableOpacity onPress={onPress}>
        <View style={styles.container}>
          <View style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'start',
          }}>
            <View style={{
              marginRight: 5
            }}>
              <Badge count={getIconStatus(item)} />
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
              <View style={{
                marginLeft: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
                {
                  !!document ?
                    <Text style={styles.title}>{`${getFileName()} (${document.orders.length} PEDIDOS)`}</Text>
                    :
                    <Text style={styles.title}>{`${getFileName()} (${item.count_orders} PEDIDOS)`}</Text>
                }
              </View>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}}>
          {Boolean(item.pdf_id) && ( 
              <div>
                  <span style={{fontWeight: 600}}> PDF ID: </span>
                  <span> {item.pdf_id} </span>
              </div>
            )}
            <UpOutlined />
          </View>
        </View>
      </TouchableOpacity>

      {
        item.status !== 'error' && !!document && !document.orders.length && (
          <View style={{
            padding: 10,
          }}>
            <Text style={{
              fontSize: 16,
              textAlign: 'center',
              fontWeight: '200'
            }}>Em processamento...</Text>

            <Button type="link" icon={<LegacyIcon type={'sync'} />}
              style={{
                marginTop: 10
              }}
              onClick={onRefresh}>
              Atualizar
            </Button>
          </View>
        )
      }

      {
        item.status === 'error' && (
          <Alert
            style={{
              background: '#e6f7ff',
              margin: 10,
              border: '1px solid #91d5ff',
            }}
            message={_.get(item, 'payload_response.message', '')}
            type="info" showIcon />
        )
      }

      {
        isLoading && (<span style={{
          margin: 10,
          fontSize: 15,
          fontWeight: 'bold',
        }}>
          Carregando...
        </span>)
      }

      {
        !isLoading && !!document && !!document.orders.length && (
          <OrdersList
            loading={false}
            onClickOrder={onClickOrder}
            onSavingOrderNumber={onSavingOrderNumber}
            onEditingNumberOrder={onEditingNumberOrder}
            data={document.orders}
            editing={editing}
            showModal={showModal}
          />
        )
      }
    </>
  )
}

export default DocumentItemExpanded
