import React, { Component } from 'react'
import './App.css'
import { Layout, Result, Button } from 'antd'
import Order from './Services/Order'
import SkeletonLoading from './Components/SkeletonLoading'

const { Header, Content } = Layout

class OrderSuccess extends Component {

  constructor (props) {
    super(props)

    this.state = {
      order: null,
      loading: true
    }
  }

  componentWillUnmount () {
    if (window.performance && window.PerformanceNavigation) {
      this.props.history.push(`/links`)
    }
  }

  componentDidMount () {
    if (!this.state.order) {
      this.loadOrder()
    } else {
      this.setState({
        order: this.state.order,
        loading: false
      })
    }
  }

  loadOrder = async () => {
    try {
      const { token } = this.props.match.params
      const order = await Order(token)

      this.setState({
        order: order,
        fetched: true
      })
    } catch (e) {
      this.setState({
        fetched: false
      })
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  render () {
    const { loading, order } = this.state

    return (
      <Layout className="layout">
        <Header className="header">
          <div>
            <h1>Pedido EDI</h1>
          </div>
        </Header>
        <Content>

          <SkeletonLoading loading={loading}/>

          {
            !!order && <Result
              status="success"
              title="Pedido enviado com sucesso!"
              subTitle={`Número do pedido:  ${order.reference}. Seu pedido foi enviado com sucesso. Obrigado!`}
              extra={[
                <Button type="primary" key="console" onClick={() =>
                  this.props.history.push(`/`)
                }>
                  Ir para pedidos
                </Button>
              ]}
            />
          }
        </Content>
      </Layout>
    )
  }
}

export default OrderSuccess
