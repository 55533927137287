import React, { Component } from 'react'
import './App.css'
import { HomeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import { Input, Button, Alert } from 'antd';
import { login } from './Services/Auth'
import getApi from './Services/index'
import LoadingOverlay from 'react-loading-overlay'

import VersionComponent from './Components/Version';

const { Footer, Content } = Layout

class Login extends Component {

  state = {
    username: '',
    password: '',
    error: null,
    loading: false
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ loading: true, error: null }, function () {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          const { company, username, password } = values

          try {
            const api = await getApi()
            const response = await api.post('/auth/', { company_id: company, user: username, password })
            const { status, data } = response
            if (status === 200) {
              login({ token: data.token });
              this.props.history.push('/links')
            } else {
              this.setState({
                error:
                  'Houve um problema com o login, verifique suas credenciais.',
                loading: false
              })
            }
          } catch (err) {
            console.log(err)
            this.setState({
              error:
                'Houve um problema com o login, verifique suas credenciais.',
              loading: false
            })
          }
        } else {
          this.setState({ error: 'Preencha e-mail e senha para continuar!', loading: false })
        }
      })
    })
  }

  render() {

    const { getFieldDecorator } = this.props.form

    return (
      <LoadingOverlay
        active={this.state.loading}
        spinner
        className={'loadingFullPage'}>
        <Layout className="layout login">
          <Content className="login-form">
            <div className="logo">
              <img src="../../assets/logo.png"
                className="logo-img"
                alt="logo"
                width={350} />
            </div>
            <Form onSubmit={this.handleSubmit}>
              <Form.Item>
                {getFieldDecorator('company', {
                  rules: [{ required: true, message: 'Informe a empresa' }],
                })(
                  <Input
                    prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Empresa"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('username', {
                  rules: [{ required: true, message: 'Informe o nome de usuário' }],
                })(
                  <Input
                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Usuário"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Informe a senha!' }],
                })(
                  <Input
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Senha"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block className="login-form-button">
                  Entrar
                </Button>
              </Form.Item>
            </Form>
            {!!this.state.error ?
              <Alert
                message={this.state.error}
                type="warning"
                closable={true}
              />
              : null
            }
          </Content>
          <VersionComponent />
          <Footer style={{ textAlign: 'center' }}>
            <a href="https://polibrasnet.com.br/" target={'_blank'} style={{
              color: '#1890FF'
            }}>Precisa de ajuda ?</a><br />
            <div>
              <img
                src="../../assets/polibras_logo.png"
                className="logo-img"
                alt="logo"
                width={70}
              />
            </div>
          </Footer>
        </Layout>
      </LoadingOverlay>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(Login)

export default withRouter(WrappedNormalLoginForm)
