import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import App from './App';
import Orders from './OrdersContainer';
import DetailOrder from './Order/DetailOrder';
import Documents from './Documents';
import Login from './Login';
import PageNotFound from './PageNotFound';
import OrderSuccess from './OrderSuccess';
import { HashRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { isAuthenticated, RECOIL_KEY } from './Services/Auth'
import { RecoilRoot,  } from 'recoil';
import recoilPersist from 'recoil-persist';

const { RecoilPersist, updateState } = recoilPersist(
  ['profile'],
  {
    key: RECOIL_KEY,
    storage: localStorage
  }
)

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuthenticated(props) ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
			)
		}
	/>
);

ReactDOM.render(
  <RecoilRoot initializeState={({set}) => {
    updateState({set});
  }}>
    <RecoilPersist />
    <Router>
      <Switch>
        <Route exact path="/login" component={Login}/>

        <PrivateRoute exact path="/" component={Orders} />
        <PrivateRoute exact path="/orders/:id/detail" component={DetailOrder} />
        <PrivateRoute exact path="/documents" component={Documents} />
        <PrivateRoute exact path="/links" component={Orders} />
        <PrivateRoute exact path="/:token" component={App} />
        <PrivateRoute exact path="/:token/success" component={OrderSuccess} />
        <PrivateRoute component={PageNotFound} />
      </Switch>
    </Router>
  </RecoilRoot>, document.getElementById('root'));

serviceWorker.unregister();
