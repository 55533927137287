import _ from 'lodash';
import getApi from './index';

export async function list() {
	const promise = await new Promise(async (resolve, reject) => {
		try {
			const api = await getApi();

			const response = await api.get('/document/', {
        params: {
          has_pagination: true,
        }
      });

			const {data} = response;

			resolve(data.results);

			// if (status >= 200 && status <= 300) {
      //
			//   const results = data.map((result)=> {
			//     return {
			//       ...result,
      //       orders: result.orders.map((order)=> {
      //         const page = _.get(order, 'original_content.pagina_inicial', 0) + 1;
      //         const pdf_id = _.get(order, 'original_content.pdf_id');
      //         return {
      //           ...order.parsed_content,
      //           id: order.id,
      //           status: order.status,
      //           created_at: order.created_at,
      //           order_number: order.order_number,
      //           temp_order_number: order.temp_order_number,
      //           pdf_id: pdf_id,
      //           initial_page: page,
      //           attempt_quantity_export: (order.attempt_revised_content || []).length
      //         }
      //       })
      //     }
      //   });
      //
			// 	resolve(results);
			// } else {
			// 	reject(new Error('Não foi possível listar os documentos!'));
			// }
		} catch (e) {
			reject(new Error(e));
		}
	});

	return promise;
}

export async function getDocument(id) {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.get(`/document/${id}/orders/`);
      const {data} = response;

      resolve({
        ...data,
        orders: data.orders.map((order)=> {
          const page = _.get(order, 'original_content.pagina_inicial', 0) + 1;
          const pdf_id = _.get(order, 'original_content.pdf_id');
          const customer_info = _.get(order, 'original_content.informacao_cliente');

          return {
            ...order.parsed_content,
            reference: order.reference,
            id: order.id,
            invalid_reason: order.invalid_reason,
            customer_info: customer_info,
            status: order.status,
            created_at: order.created_at,
            order_number: order.order_number,
            temp_order_number: order.temp_order_number,
            pdf_id: pdf_id,
            initial_page: page,
            attempt_quantity_export: (order.attempt_revised_content || []).length,
            exists_in_other_order: order.exists_in_other_order,
            duplicate_reference: order.duplicate_reference,
          }
        })
      });
    } catch (e) {
      reject(new Error('Não foi possível obter os detalhes do documento!'));
    }
  });

  return await promise;
}

export async function upload(file, onCallbackProgress) {
  return await new Promise(async (resolve, reject) => {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      const extra_options = {
        timeout: 60000 * 5,
        onUploadProgress: data => {
          onCallbackProgress(Math.round((100 * data.loaded) / data.total))
        },
      }

      const api = await getApi(headers, extra_options);
      const form = new FormData();
      form.append('file', file, file.file_name);

      const response = await api.post(`/document/`, form);
      const {status, data} = response;

      if (status >= 200 && status <= 300) {
        resolve({
          ...data.document,
          orders: [],
        });
      } else {
        reject(new Error('Não foi possível enviar a arquivo!'));
      }
    } catch (e) {
      reject(new Error(e));
    }
  });
}
