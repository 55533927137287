import React from "react";
import {List, Skeleton} from "antd";

export default class SkeletonLoading extends React.Component {

	render() {

		const {loading} = this.props;

		if (!loading) {
			return null;
		}

		return (
			<List
				itemLayout="horizontal"
				dataSource={[1, 2, 3, 4, 5]}
				renderItem={item => (
					<List.Item>
						<div style={{
							display: 'flex',
							flex: 1,
							flexDirection: 'row',
						}}>
							<Skeleton title paragraph={{ rows: 3 }} loading={loading} active avatar={{
								shape: 'square', size: 'large'
							}}>
							</Skeleton>
						</div>
					</List.Item>
				)}
			/>
		)
	}

}
