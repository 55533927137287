import React from 'react';
import './App.css';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Alert, Button, Radio, Layout, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import Header from './Components/Header'
import Config from './../package.json'
import OrdersList from './Screens/Orders/OrdersList'
import { hideToolbar } from './Services/Auth'

import VersionComponent from './Components/Version';

const { Content } = Layout

const Orders = ({
  profile,
  onChangeFilter,
  filter,
  current_item_delete,
  loading,
  onClickOrder,
  has_more,
  handleCancel,
  handleOk,
  handleInfiniteOnLoad,
  onSavingOrderNumber,
  onEditingNumberOrder,
  open_modal,
  onRefresh,
  data,
  editing,
  showModal,
  onRefreshList,
}) => {

  return (
    <Layout>
      <Header title={'Pedidos'} />

      {
        profile.version.code && Config.code_version < profile.version.code &&
        (
          <div style={{
            marginTop: hideToolbar() ? 0 : 70,
            marginBottom: -50,
            padding: '0px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Alert
              message="Novidades"
              description={
                <span>Há uma nova versão disponível!</span>
              }
              type="warning"
              showIcon
            />

            <Button type="link" icon={<LegacyIcon type={'sync'} />}
                    style={{
                      marginTop: 10
                    }}
                    onClick={onRefresh}>
              Clique aqui para atualizar
            </Button>
          </div>
        )
      }

      <Content style={{ padding: '0 20px' }}>
        <div style={{
          marginTop: (hideToolbar() && (profile.version.code && Config.code_version < profile.version.code)) ? 80 : (hideToolbar() ? 10 : 70),
          textAlign: 'center'
        }}>
          <Radio.Group defaultValue="pending"
                       size={'large'}
                       buttonStyle="solid"
                       focus={() => {
                         console.log('Focus')
                       }}
                       value={filter}
                       onChange={onChangeFilter}>
            <Radio.Button value="pending">Pendentes</Radio.Button>
            <Radio.Button value="revised">Enviados</Radio.Button>
            <Radio.Button value="all">Todos</Radio.Button>
          </Radio.Group>
        </div>

        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={handleInfiniteOnLoad}
          hasMore={!loading && has_more}
          useWindow={true}
        >
          <OrdersList
            onRefreshList={onRefreshList}
            loading={loading}
            onClickOrder={onClickOrder}
            onSavingOrderNumber={onSavingOrderNumber}
            onEditingNumberOrder={onEditingNumberOrder}
            data={data}
            editing={editing}
            showModal={showModal}
          />
        </InfiniteScroll>
      </Content>
      <Modal
        title="Você deseja realmente cancelar esse pedido?"
        visible={open_modal}
        cancelText={'Não'}
        okText={'Sim'}
        onOk={handleOk}
        onCancel={handleCancel}>
        <h2>
          {current_item_delete && <span> #{current_item_delete.reference}</span>}
        </h2>
      </Modal>
      <VersionComponent />
    </Layout>
  );
}

export default withRouter(Orders)
