import React from 'react';
import { Button, Divider } from 'antd';
import { MobileOutlined } from '@ant-design/icons';
import { color } from '../common';
import { Icon as LegacyIcon } from '@ant-design/compatible';

export default function OrderButtons({
  allowCloseOrder,
  allowSendOrder,
  onFinish,
  showModalSend,
  showModalFeature,
  loading = false,
}) {
  return allowCloseOrder || allowSendOrder ? (
    <div
      className='display-flex-column'
      style={{
        alignItems: 'center',
      }}
    >
      {allowCloseOrder && (
        <Button
          type='primary'
          shape='round'
          size='large'
          icon={<LegacyIcon type='check' />}
          disabled={loading}
          onClick={() => {
            onFinish(false);
          }}
        >
          {loading ? 'Enviando pedido...' : 'Enviar pedido'}
        </Button>
      )}

      {allowSendOrder && allowCloseOrder && (
        <>
          <Divider
            style={{
              marginBottom: 5,
              marginTop: 5,
            }}
          />
          <span className='fw-600'>OU</span>
          <Divider
            style={{
              marginBottom: 5,
              marginTop: 5,
            }}
          />
        </>
      )}

      {allowSendOrder && (
        <Button
          type='success'
          shape='round'
          size='large'
          style={{
            color: '#FFF',
            backgroundColor: color.successColor,
          }}
          icon={<MobileOutlined />}
          onClick={showModalSend}
        >
          Enviar para Força de Vendas
        </Button>
      )}
    </div>
  ) : (
    <div
      className='display-flex-column'
      style={{
        alignItems: 'center',
      }}
    >
      <Button
        type='primary'
        shape='round'
        size='large'
        icon={<LegacyIcon type='check' />}
        onClick={showModalFeature}
      >
        Enviar pedido
      </Button>
    </div>
  );
}
