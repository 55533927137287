import React, { useEffect, useState } from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, message } from 'antd';
import { Modal} from 'antd'
import _ from 'lodash'

const { Option } = Select

const ModalSaleConditions = (props) => {

  const { selected, itemSelected } = props;
  let descriptions = [];
  if (itemSelected) {
    descriptions = itemSelected.value.split(' | ');
  }

  let cond_split = (selected || '').split(';');

  const [org_venda, setOrgVenda] = useState([]);
  const [tipo_venda, setTipoVenda] = useState([]);
  const [tabela_preco, setTabelaPreco] = useState([]);
  const [meios, setMeios] = useState([]);
  const [prazos, setPrazos] = useState([]);

  const [selected_org_venda, setSelectedOrgVenda] = useState(cond_split[0]);
  const [selected_meio, setSelectedMeio] = useState(cond_split[1]);
  const [selected_prazo, setSelectedPrazo] = useState(cond_split[2]);
  const [selected_tabela_preco, setSelectedTabelaPreco] = useState(cond_split[3]);
  const [selected_tipo_venda, setSelectedTipoVenda] = useState(cond_split[4]);

  useEffect(() => {
    loadConditions();
  }, [
    selected_org_venda,
    selected_tipo_venda,
    selected_tabela_preco,
    selected_meio,
    selected_prazo
  ]);

  const loadConditions = () => {
    const items = props.items || [];

    let conditions = {
      org_venda: [],
      tipo_venda: [],
      tabela_preco: [],
      meios: [],
      prazos: [],
    };

    items.forEach((item)=> {
      const codes = item.code.split(';');
      const labels = item.value.split(' | ');
      const org_venda = codes[0];
      const meio = codes[1];
      const tipo_venda = codes[4];
      const tabela_preco = codes[3];

      conditions.org_venda.push({
        code: codes[0],
        label: labels[0],
      });

      if (selected_org_venda === org_venda) {
        conditions.tipo_venda.push({
          code: codes[4],
          label: labels[4],
        });

        if (tipo_venda === selected_tipo_venda) {
          conditions.tabela_preco.push({
            code: codes[3],
            label: labels[3],
          });

          if (tabela_preco === selected_tabela_preco) {
            conditions.meios.push({
              code: codes[1],
              label: labels[1],
            });

            if (meio === selected_meio) {
              conditions.prazos.push({
                code: codes[2],
                label: labels[2],
              });
            }
          }
        }
      }
    });

    setOrgVenda(_.uniqBy(conditions.org_venda, 'code'));
    setTabelaPreco(_.uniqBy(conditions.tabela_preco, 'code'));
    setTipoVenda(_.uniqBy(conditions.tipo_venda, 'code'));
    setMeios(_.uniqBy(conditions.meios, 'code'));
    setPrazos(_.uniqBy(conditions.prazos, 'code'));
  }

  const handleClick = () => {

    if (!selected_org_venda) {
      message.error('Selecione a condição de venda');
      return;
    }

    if (!selected_tipo_venda) {
      message.error('Selecione o tipo de venda');
      return;
    }

    if (!selected_tabela_preco) {
      message.error('Selecione a tabela de preço');
      return;
    }

    if (!selected_meio) {
      message.error('Selecione o meio');
      return;
    }

    if (!selected_prazo) {
      message.error('Selecione o prazo');
      return;
    }

    const conditions = [
      selected_org_venda,
      selected_meio,
      selected_prazo,
      selected_tabela_preco,
      selected_tipo_venda,
    ];

    props.onSelect(conditions.join(';'))
  }

  const onChangeOrgVend = (value) => {
    setSelectedOrgVenda(value);
    setSelectedMeio(null);
    setSelectedPrazo(null);
    setSelectedTabelaPreco(null);
    setSelectedTipoVenda(null);

    props.form.setFieldsValue({
      meio: undefined,
      prazo: undefined,
      tabela_preco: undefined,
      tipo_venda: undefined,
    });
  }

  const onChangeTipoVenda = (value) => {
    setSelectedTipoVenda(value);
    setSelectedTabelaPreco(null);
    setSelectedMeio(null);
    setSelectedPrazo(null);

    props.form.setFieldsValue({
      meio: undefined,
      prazo: undefined,
      tabela_preco: undefined,
    });
  }

  const onChangeTabelaPreco = (value) => {
    setSelectedTabelaPreco(value);
    setSelectedMeio(null);
    setSelectedPrazo(null);

    props.form.setFieldsValue({
      meio: undefined,
      prazo: undefined,
    });
  }

  const onChangeMeio = (value) => {
    setSelectedMeio(value);
    setSelectedPrazo(null);

    props.form.setFieldsValue({
      prazo: undefined,
    });
  }

  const onChangePrazo = (value) => {
    setSelectedPrazo(value);
  }

  const { getFieldDecorator } = props.form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <div>
      <Modal
        title="Condição de venda"
        centered
        maskClosable={false}
        cancelText={'Cancelar'}
        visible={props.visible}
        onOk={handleClick}
        onCancel={() => props.onCancel()}
      >
        <Form {...formItemLayout}>
          <Form.Item label="Organização de venda:" htmlFor={'org_vend'}>
            {getFieldDecorator('org_vend', { initialValue: descriptions ? descriptions[0] : null })(
              <Select
                showSearch
                id={'org_vend'}
                name={'org_vend'}
                placeholder="Organização de venda"
                optionFilterProp="children"
                onChange={onChangeOrgVend}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  org_venda.map((item)=> {
                    return (<Option value={item.code} key={item.code}>{item.label}</Option>)
                  })
                }
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Tipo de venda:" htmlFor={'tipo_venda'}>
            {getFieldDecorator('tipo_venda', { initialValue: descriptions ? descriptions[4] : null })(
              <Select
                showSearch
                id={'tipo_venda'}
                name={'tipo_venda'}
                placeholder="Tipo de venda"
                optionFilterProp="children"
                onChange={onChangeTipoVenda}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  tipo_venda.map((item)=> {
                    return (<Option value={item.code} key={item.code}>{item.label}</Option>)
                  })
                }
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Tabela de preço:" htmlFor={'tabela_preco'}>
            {getFieldDecorator('tabela_preco', { initialValue: descriptions ? descriptions[3] : null })(
              <Select
                showSearch
                id={'tabela_preco'}
                name={'tabela_preco'}
                placeholder="Tabela de preço"
                optionFilterProp="children"
                onChange={onChangeTabelaPreco}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  tabela_preco.map((item)=> {
                    return (<Option value={item.code} key={item.code}>{item.label}</Option>)
                  })
                }
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Meio:" htmlFor={'meio'}>
            {getFieldDecorator('meio', { initialValue: descriptions ? descriptions[1] : null })(
              <Select
                showSearch
                id={'meio'}
                name={'meio'}
                placeholder="Meio"
                optionFilterProp="children"
                onChange={onChangeMeio}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  meios.map((item)=> {
                    return (<Option value={item.code} key={item.code}>{item.label}</Option>)
                  })
                }
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Prazo:" htmlFor={'prazo'}>
            {getFieldDecorator('prazo', { initialValue: descriptions ? descriptions[2] : null })(
              <Select
                showSearch
                id={'prazo'}
                name={'prazo'}
                placeholder="Prazo"
                optionFilterProp="children"
                onChange={onChangePrazo}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  prazos.map((item)=> {
                    return (<Option value={item.code} key={item.code}>{item.label}</Option>)
                  })
                }
              </Select>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

const WrappedDemo = Form.create({ name: 'customized_form_controls' })(ModalSaleConditions);

export default WrappedDemo;
