import React, {useEffect, useState} from 'react'
import {Form, Icon as LegacyIcon} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Divider, Input, List, message, Modal, Spin} from 'antd';
import {CheckCircleOutlined, LockOutlined} from "@ant-design/icons";
import {TouchableOpacity} from "react-native-web";
import {useDebounce} from 'use-lodash-debounce';
import {getSuggestedClients, setCustomer} from "../Services/Order";
import UtilsHelper from "../Util/UtilsHelper";
import ExtraInfoCustomerNotFound from "./ExtraInfoCustomerNotFound";

const ModalChooseCustomer = (props) => {
  const orderId = props.order.id;

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const [value, setValue] = useState();
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    const loadSearch = async () => {
      setIsLoading(true);
      const items = await getSuggestedClients(props.order.id, value);

      setProducts([...items]);
      setIsLoading(false);
    };

    loadSearch();
  }, [debouncedValue]);

  const handleClick = async () => {
    if (!selected) {
      message.warning('Selecione um cliente!');
      return;
    }

    message.loading('Definindo cliente...');

    try {
      const payload = {
        customer_nin: selected.nin,
      }

      const result = await setCustomer(orderId, payload);

      setTimeout(()=> {
        const modal = Modal.success({
          title: 'Cliente alterado com sucesso',
          content: result.message || 'O cliente do pedido foi atualizado com sucesso!',
        });

        props.onCancel();

        if (props.reloadListOrders) {
          props.reloadListOrders();
        }
      }, 300);
    } catch (e) {
      console.log('e', e);
      message.error('Não foi possível atualizar o cliente do pedido!')
    } finally {
      message.destroy();
    }
  }

  const {getFieldDecorator} = props.form;
  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 8},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 16},
    },
  };

  const renderItem = (item) => {
    return (
      <TouchableOpacity key={item.id}>
        <List.Item
          style={{
            borderBottom: '1px solid #e6e6e6',
          }}
          key={item.id}
          onClick={() => {
            setSelected(item)
          }}>
          <List.Item.Meta
            description={<div>
              <span style={{
                fontWeight: '600',
              }}>{item.code}</span>
              <Divider style={{
                fontWeight: '400',
              }} type="vertical"/>
              <span style={{
                fontWeight: '400',
                fontSize: 14,
              }}>CNPJ: {UtilsHelper.formatCNPJOrCPF(item.nin)}</span>
              <br/>
              <span style={{
                fontWeight: '600',
                fontSize: 16,
              }}>{item.name}</span>
              <br/>
              <span>
                <LegacyIcon type={'home'} style={{marginRight: 4}}/>
                <span style={{
                  fontWeight: '600',
                  fontSize: 12,
                }}>{item.flat_address || 'Sem endereço'}</span>
              </span>
              {
                item.is_blocked && (
                  <p style={{
                    color: 'red'
                  }}>
                    <LockOutlined style={{marginRight: 4}} />
                    <span style={{
                      fontWeight: '600',
                      fontSize: 12,
                    }}>{'Cliente bloqueado'}</span>
                  </p>
                )
              }
            </div>
            }
          />
          <div className={"item-btn-confirm " + ((selected && (item.id == selected.id)) ? "item-btn-confirm-selected" : "")}>
            <CheckCircleOutlined/>
          </div>
        </List.Item>
      </TouchableOpacity>
    );
  };

  return (
    <Modal
      title={"Lista de Sugestões"}
      className="modal-choose-customer"
      centered
      maskClosable={false}
      cancelText={'Cancelar'}
      visible={props.visible}
      onOk={handleClick}
      onCancel={() => props.onCancel()}
    >

      <div style={{
        borderRadius: 8,
        border: '1px solid #c3c3c3',
        marginBottom: 5,
        padding: 5,
      }}>
        <ExtraInfoCustomerNotFound
          item={props.order}
          customer_info={props.order.customer_info}/>
      </div>

      <Form {...formItemLayout}>
        <Form.Item label="Pesquisa: " htmlFor={'search'}>
          {getFieldDecorator('search', {})(
            <Input
              onChange={e => setValue(e.target.value)}
              placeholder={'Comece a digitar para pesquisar'}/>
          )}
        </Form.Item>
      </Form>

      {
        isLoading && (
          <div className="loading">
            <Spin tip={'Pesquisando...'}/>
          </div>
        )
      }

      {
        !isLoading && !!products.length && (
          <List className="list-items-search">
            {
              products.map((item) => {
                return (
                  renderItem(item)
                )
              })
            }
          </List>
        )
      }
    </Modal>
  )
}

const ModalChooseCustomerForm = Form.create({
  name: 'modal_choose_customer'
})(ModalChooseCustomer);

export default ModalChooseCustomerForm;
