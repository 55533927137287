import React from 'react'
import {Img} from "react-image";
import {URL_BASE} from "../Services";
import {Modal, Spin} from "antd";

const ModalImageInfo = (props) => {
  const {id_image, title, visible, onCancelModal} = props;

  return (
    <Modal
      title={'Imagem ' + (title || '')}
      footer={null}
      visible={visible}
      onCancel={() => {
        onCancelModal();
      }}
    >
      {
        id_image && (
          <Img
            src={URL_BASE + '/cell_image/?img_id=' + id_image}
            style={{
              maxWidth: '100%',
            }}
            loader={
              <div style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <Spin/>
              </div>
            }
          />
        )
      }
    </Modal>
  )
}

export default ModalImageInfo;
