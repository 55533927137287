import Config from "../Config";

const ITEM_PRECISION_ACCURACY = Config.ITEM_PRECISION_ACCURACY;

export const isLowPrecision = (precision) => {
  return precision <= ITEM_PRECISION_ACCURACY;
};

export const ORDER_STATUS = {
  CREATED: 'created',
  VIEWED: 'viewed',
  REVISED: 'revised',
  CANCELED: 'canceled',
  CLIENT_NOT_FOUND: 'client_not_found',
  SENT_TO_DEVICE: 'sent_to_device',
}
