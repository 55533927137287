export const TOKEN_KEY = "@polibras-token";
export const HIDE_TOOLBAR = "@hide-toolbar";
export const RECOIL_KEY = "@polibras-edi-recoil";

export const isAuthenticated = (props) => {
	const params = new URLSearchParams(props.location.search);
	const token = params.get('token');
	const hideToolbar = params.get('hide_toolbar');

	if (hideToolbar) {
		localStorage.setItem(HIDE_TOOLBAR, hideToolbar);
	}

	if (token) {
		login({ token } );

		let uri = window.location.toString();
		if (uri.indexOf("?") > 0) {
			let clean_uri = uri.substring(0, uri.indexOf("?"));
			window.history.replaceState({}, document.title, clean_uri);
		}
	}

	return localStorage.getItem(TOKEN_KEY) !== null;
}

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = (data) => {
	const { token } = data;
	localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(RECOIL_KEY);
};

export const hideToolbar = () => !!localStorage.getItem(HIDE_TOOLBAR);
