import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { HistoryOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Drawer, Tag, Spin, Divider, Modal, Result, message, Alert } from 'antd'
import getProfile from '../Services/Profile'
import moment from 'moment'
import { logout, hideToolbar } from '../Services/Auth'
import Config from './../../package.json'
import { synchronize } from '../Services/Action'
import { useRecoilState } from 'recoil'
import profileState from '../Stores/Atoms/ProfileAtom'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin/>

const Profile = (props) => {
  const [profile, setProfile] = useRecoilState(profileState)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open_modal, setOpenModal] = useState(false)
  const [processing_synchronize, setProcessingSynchronize] = useState(false)
  const [error_synchronize, setErrorSynchronize] = useState(false)
  const [open_modal_version, setOpenModalVersion] = useState(false)
  const { company, seller, user } = profile

  const loadProfile = useCallback(async () => {
    setLoading(true)
    try {
      const profile = await getProfile()
      setProfile(profile)
    } catch (error) {
      message.error('Não foi possível carregar seu perfil!')
    } finally {
      setLoading(false)
    }
  }, [setProfile]);

  useEffect(() => {
    loadProfile()

    const interval = setInterval(() => {
      loadProfile()
    }, 30000)
    return () => clearInterval(interval)
  }, [loadProfile])



  const onClickLogout = () => {
    logout()
    props.history.push(`/`)
  }

  const handleCancel = () => {
    setOpenModal(false)
  }

  const showModal = (evt) => {
    evt.stopPropagation()
    evt.nativeEvent.stopImmediatePropagation()
    setOpenModal(true)

    return true
  }

  const onClose = () => {
    setVisible(false)
  }

  const onSync = async () => {
    setProcessingSynchronize(true)
    setErrorSynchronize(false)

    try {
      const result = await synchronize(profile.id)

      const seller = profile.seller
      const updated_profile = {
        ...profile,
        seller: {
          ...seller,
          last_sync_start_at: moment(),
          synchronizing: true
        }
      }

      setProfile(updated_profile)

      message.success(result.message)
    } catch (e) {

      console.log('onSync e', e)

      setErrorSynchronize(false)

      message.error('Não foi possível solicitar a sincronização da base!')
    } finally {
      setProcessingSynchronize(false)
    }
  }

  const getInfoBase = () => {
    const { seller } = profile
    let diffMinutes = 0
    if (seller.last_sync_success_at) {
      const startTime = moment(seller.last_sync_success_at)
      const end = moment()
      diffMinutes = moment.duration(end.diff(startTime)).asMinutes()
    }

    if (seller.synchronizing) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <p>Estamos atualizando sua base!</p>
          <Tag color="orange">
            <span style={{
              fontWeight: 600
            }}>Atualização iniciada às: </span>
            {moment(seller.last_sync_start_at).format('DD/MM/YYYY HH:mm')}
          </Tag>
          <Divider type="horizontal"/>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}/>
        </div>
      )
    }

    if (seller.last_sync_success_at && diffMinutes < 20) {
      return (
        <Result
          style={{
            padding: '10px'
          }}
          status="success"
          subTitle={
            <div>
              <p style={{
                fontWeight: 600
              }}>TUDO OK!</p>
              <p style={{
                fontStyle: 'oblique'
              }}>Você pode enviar seus pedidos normalmente!</p>
              <span style={{
                fontWeight: 600
              }}>Boas vendas!</span>
            </div>
          }
        />
      )
    } else {
      return (
        <Alert
          style={{
            maxWidth: "380px",
            backgroundColor: "#E5FBFF",
            border: "1px solid #1AD9FF",
          }}
          icon={<HistoryOutlined style={{ color: "#006A80", marginBottom: 40 }} />}
          type="info"
          showIcon
          message={
            <div>
              <span style={{ color: "#006A80", display: 'block' }}>Fique tranquilo!</span>
              <span style={{ color: "#006A80", display: 'block' }}>
                O nosso sistema inteligente mantém as suas
              </span>
              <span style={{ color: "#006A80" }}>
                informações sempre atualizadas automaticamente.
              </span>
            </div>
          }
          />
        );
        // <div className="desc" style={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   alignItems: 'center'
        // }}>
        //   <Paragraph>
        //     <Text
        //       style={{
        //         fontSize: 16,
        //         fontWeight: '500'
        //       }}
        //     >
        //       Sua base não está atualizada
        //     </Text>
        //   </Paragraph>
        //   <Paragraph>
        //     <InfoCircleOutlined style={{ color: 'red' }}/> Precisamos atualizar sua base!
        //   </Paragraph>

        //   <Paragraph>
        //     <Text
        //       style={{
        //         fontSize: 12,
        //         fontWeight: '400'
        //       }}
        //     >
        //       Clique no botão abaixo
        //     </Text>
        //   </Paragraph>

        //   <Button
        //     onClick={onSync}
        //     loading={processing_synchronize}
        //     style={{
        //       backgroundColor: 'orange',
        //       color: '#FFF'
        //     }} key="sync">
        //     Sincronizar base
        //   </Button>
        // </div>
    }
  }

  const descriptions = profile.version.description || ''

  return <>
    {
      <UserOutlined
        style={{ color: '#fff', fontSize: '20px' }}
        onClick={() => {
          setVisible(true)
        }}/>
    }
    <Drawer
      width={'90%'}
      title={<div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <span>Informações</span>

        {
          !hideToolbar() && <Button type="danger" onClick={showModal}>
            Sair
          </Button>
        }
      </div>}
      closable={false}
      onClose={onClose}
      visible={visible}
    >

      <div>
        {
          !user.id ? <Spin indicator={antIcon}/> : <>
            <h3>Empresa</h3>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <div>
                <span style={{ fontWeight: 600 }}>Nome: </span> <span>{company.name}</span>
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>CNPJ: </span> <span>{company.nin}</span>
              </div>
            </div>

            <Divider type="horizontal"/>

            <h3>Vendedor</h3>
            <div style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div>
                <span style={{ fontWeight: 600 }}>Código: </span> <span>{seller.login}</span>
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Nome: </span> <span>{seller.name}</span>
              </div>
            </div>

            <Divider type="horizontal"/>

            <h3>Atualização dos dados</h3>

            <div>
              {getInfoBase()}
            </div>

            <div onClick={() => {
              setOpenModalVersion(true)
            }}
                 style={{
                   position: 'fixed',
                   left: '40%',
                   bottom: 10,
                   textDecoration: 'underline',
                   color: 'blue',
                   cursor: 'pointer'
                 }}>
              <span style={{ fontWeight: 700 }}>Versão: </span> <span>{Config.version}</span>
            </div>
          </>
        }
      </div>
    </Drawer>

    <Modal
      visible={open_modal}
      cancelText={'Não'}
      okText={'Sim'}
      onOk={onClickLogout}
      onCancel={handleCancel}>
      <h3>Deseja realmente sair ?</h3>
    </Modal>

    <Modal
      title="Versão"
      footer={null}
      visible={open_modal_version}
      onCancel={() => {
        setOpenModalVersion(false)
      }}
    >
      {
        descriptions.split('\n').map((description, index) => {
          return <p key={index}>{description}</p>
        })
      }
    </Modal>
  </>
}

export default withRouter(Profile)
