import getApi from './index';

export default async function getProfile() {
	const promise = await new Promise(async (resolve, reject) => {
		try {
			const api = await getApi();
			const response = await api.get('/me/');
			const {status, data} = response;

			if (status >= 200 && status <= 300) {
				resolve(data);
			} else {
				reject(new Error('Não foi possível carregar o perfil!'));
			}
		} catch (e) {
			reject(new Error(e));
		}
	});

	return promise;
}
