import StringMask from 'string-mask';
import moment from 'moment';
import VMasker from "vanilla-masker";

class UtilsHelper {

    formatPhone(phone) {
        var exp = /\.|\-|\//g;
        let string = (phone || '').toString().replace(exp, '');
        return VMasker.toPattern(string || '', "(99) 9999-9999")
    }

    formatCNPJOrCPF(cnpj_cpf) {
        var exp = /\.|\-|\//g;
        let string = (cnpj_cpf || '').toString().replace(exp, '');
        let format = '00.000.000/0000-00';

        if (string.length === 11) {
            format = '000.000.000-00';
        }

        let formatter = new StringMask(format);
        return formatter.apply(string);
    }

    formatPhone(text) {
        return VMasker.toPattern(text || '', "(99) 9999-9999");
    }

    parsePgLocation(location) {
        location =  location || '(0,0)';
        let point = location.replace('(', '').replace(')', '').split(',');

        return {
            lat: parseFloat(point[0]),
            lng: parseFloat(point[1])
        };
    }

    has(object, array, key = 'id') {
        let result = array.filter((item) => {
            return object[key] === item[key];
        });

        return !!result.length;
    }

    toLocal(date) {
        return moment(date).utc(date).local();
    }

    local() {
        return moment().utc().local();
    }

    now() {
        return this.local().format('YYYY-MM-DD');
    }

    format(date) {
        return date.format('YYYY-MM-DD');
    }

    toLocalDate(date) {
        let format = 'DD/MM/YYYY';
        return this.toLocal(date, format).format(format);
    }

    toLocalDateTime(date) {
        let format = 'DD/MM/YYYY HH:mm';
        return this.toLocal(date, format).format(format);
    }

    toLocalTime(date) {
        let format = 'HH:mm';
        return this.toLocal(date, format).format(format);
    }

    removeAccents(text) {
      return (text || '').toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }

};

export default new UtilsHelper;
