import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { message } from 'antd';
import { cancel, list, save } from './Services/Order';
import { withRouter } from 'react-router-dom';
import profileState from './Stores/Atoms/ProfileAtom';
import Orders from './Orders';
import { useRecoilState } from 'recoil';
import {ORDER_STATUS} from "./Util";

import Axios from 'axios';

const OrdersContainer = (props) => {
  const [profile, setProfile] = useRecoilState(profileState);
  const [filter, setFilter] = useState('pending');
  const [data, setData] = useState([]);
  const [has_more, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [open_modal, setOpenModal] = useState(false);
  const [editing, setEditing] = useState({});
  const [current_item_delete, setCurrentItemDelete] = useState(null);

  let sourceLastRequest = useRef();

  useEffect(() => {
    loadData();
  }, [filter, page]);

  const loadData = () => {
    fetchData(res => {
      setData(res.results);
      setCount(res.count);
      setNext(res.next);
      setLoading(false);
    })
  }

  const fetchData = async (callback) => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    sourceLastRequest.current = source;

    try {
      const data = await list({ page: page, status_filter: filter, cancelToken: source.token })
      callback(data)
    } catch (e) {
      setLoading(false);

      message.error('Não foi possível carregar os pedidos!')
    }
  }

  const handleInfiniteOnLoad = () => {
    setLoading(true);

    if (data.length >= count) {
      setHasMore(false);
      setLoading(false);

      return;
    }

    fetchData(res => {
      setData(data.concat(res.results));
      setCount(res.count);
      setNext(res.next);
      setPage(res.page);
      setLoading(false);
    })
  }

  const onChangeFilter = (e) => {
    e.preventDefault();

    if(sourceLastRequest.current && sourceLastRequest.current.cancel) {
      sourceLastRequest.current.cancel('Canceled')
    }

    setFilter(e.target.value);
    setPage(1);
    setLoading(true);
    setData([]);
  }

  const onClickOrder = (item) => {
    const { id, status, temp_order_number } = item;
    if (item.reference !== 'INEXISTENTE') {
      if ((status === ORDER_STATUS.REVISED || status === ORDER_STATUS.SENT_TO_DEVICE) && !!temp_order_number) {
        props.history.push(`/orders/${id}/detail`);
        return;
      }

      if ([
        ORDER_STATUS.SENT_TO_DEVICE,
        ORDER_STATUS.REVISED,
        ORDER_STATUS.CANCELED
      ].indexOf(status) === -1) {
        props.history.push(`${id}`)
      }
    }
  }

  const showModal = (item, evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    setOpenModal(true);
    setCurrentItemDelete(item);

    return true;
  }

  const handleOk = async () => {
    try {
      await cancel(current_item_delete.id);

      setOpenModal(false);
      setPage(page);

      loadData();

      message.success('Pedido cancelado com sucesso!')
    } catch (e) {
      message.error('Não foi possível cancelar o pedido! Tente novamente!')
    }
  }

  const handleCancel = () => {
    setOpenModal(false);
  }

  const onSavingOrderNumber = async (item) => {
    try {
      const reference = window.document.getElementById(`reference-${item.id}`).value;
      if (reference) {
        const token = item.id;
        const payload = {
          reference: reference,
          products: item.products,
        }

        await save(token, payload);
        onEditingNumberOrder(item);
        message.success('Pedido salvo!');

        props.history.push(`${item.id}`);
      } else {
        message.warning('Informe o número do pedido')
      }
    } catch (e) {
      message.error('Não foi possível salvar o pedido!')
    }
  }

  const onEditingNumberOrder = (item) => {
    editing[item.id] = !editing[item.id];

    setEditing({
      ...editing
    });

    if (editing[item.id]) {
      setTimeout(()=> {
        document.getElementById(`reference-${item.id}`).focus();
      }, 200);
    }
  }

  const onRefresh = () => {
    window.location.reload(true);
  }

  return (
    <Orders
      onRefreshList={loadData}
      current_item_delete={current_item_delete}
      has_more={has_more}
      profile={profile}
      data={data}
      filter={filter}
      onChangeFilter={onChangeFilter}
      editing={editing}
      loading={loading}
      handleInfiniteOnLoad={handleInfiniteOnLoad}
      showModal={showModal}
      onClickOrder={onClickOrder}
      open_modal={open_modal}
      handleOk={handleOk}
      onSavingOrderNumber={onSavingOrderNumber}
      onEditingNumberOrder={onEditingNumberOrder}
      handleCancel={handleCancel}
      onRefresh={onRefresh} />
  );
}

export default withRouter(OrdersContainer)
