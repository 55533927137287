import React from "react";

const styles = {
	flexDirectionColumn: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
	},
	flexDirectionRow: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	countItemsContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	countItemsLabel: {
		fontWeight: '400',
		fontSize: '16px'
	},
	countItemsValue: {
		fontWeight: '300',
		fontSize: '16px'
	},
	totalContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: '10px',
		paddingTop: '10px',
	},
	separator: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderTop: '1px solid #d9d9d9',
		marginTop: '10px',
		paddingTop: '10px',
	},
	totalLabel: {
		fontWeight: '700',
		fontSize: '16px'
	},
	totalValue: {
		fontSize: '16px'
	}
};

export default class FooterOrder extends React.Component {

	render() {
		const { count, total } = this.props;

		return (
			<div style={{
			  ...styles.flexDirectionColumn,
        marginLeft: 20,
        marginRight: 20,
			}}>
				<div style={styles.countItemsContainer}>
					<span style={styles.countItemsLabel}>Quantidade produtos</span>
					<span style={styles.countItemsValue}>{count}</span>
				</div>
				<div style={styles.totalContainer}>
					<span style={styles.totalLabel}>Total</span>
					<span style={styles.totalValue}>{total}</span>
				</div>
				<div style={styles.separator}></div>
			</div>
		)
	}
}
