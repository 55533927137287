import {color} from '../../common';

const styles = {
  page_header: {
    backgroundColor: color.primaryColor,
    padding: 10,
    paddingLeft: 20
  },
  icon_back: {
    color: '#fff'
  },
  title: {
    fontSize: '14px',
    color: '#fff',
    lineHeight: 0,
    marginTop: 10
  }
};

export default styles;
