/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, Radio, Input, List, Divider, Spin } from 'antd';
import { Form } from '@ant-design/compatible';
import { CheckCircleOutlined } from "@ant-design/icons";
import { TouchableOpacity } from "react-native-web";
import { useDebounce } from 'use-lodash-debounce';
import ImageLoader from "react-load-image";

import { getSuggestedProducts } from "../Services/Order";


const ModalProductSelect = (props) => {
  const { suggestions = [], order, onCancel, itemCurrent, onChangeProductSuggest } = props;

  const [tab, setTab] = useState(suggestions.length > 0 ? 'suggestions' : 'all');

  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();

  const debouncedValue = useDebounce(search, 500);

  const { getFieldDecorator } = props.form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  useEffect(() => {
    if (debouncedValue) {
      const itemsFiltered = suggestions.filter((suggestion) => {
        return suggestion.name.includes(debouncedValue) || suggestion.product_code?.includes(debouncedValue);
      });

      setFilteredSuggestions([...itemsFiltered]);
    } else {
      setFilteredSuggestions([...suggestions]);
    }
    const loadSearch = async () => {
      setLoading(true);
      const { results, has_more } = await getSuggestedProducts(order.id, search);
      setHasMore(has_more);
      setLoading(false);
      setPage(1);
      setProducts([...results]);
    };
    loadSearch();
  }, [debouncedValue]);
  function onChangeTab(e) {
    setTab(e.target.value);
  }


  function onHandleClick() {
    if (selected) {
      onChangeProductSuggest(itemCurrent, selected);
      onCancel();
    }
  }

  async function loadMore() {
    if (hasMore) {
      setLoading(true);
      const { results, has_more } = await getSuggestedProducts(order.id, search, page + 1);
      setProducts([...products, ...results]);
      setHasMore(has_more);
      setLoading(false);
      setPage(page => page + 1);
    }
  }

  function renderProduct(product) {
    return <TouchableOpacity key={product.id}>
      <List.Item style={{
        borderBottom: '1px solid #e6e6e6',
      }}
        key={product.id}
        onClick={() => {
          setSelected(product)
        }}>
        <List.Item.Meta
          avatar={
            <div style={{
              width: '40px',
              height: '80px',
            }}>
              <ImageLoader src={product.image}>
                <img src='../../assets/no-image.png' alt='' className="item-image" />
              </ImageLoader>
            </div>
          }
          title={<span className="item-title">{product.name}</span>}
          description={<div>
            <span>EAN: {product.ean}</span>
            <Divider type="vertical" />
            <span>Emb: {product.unit_description || '-'}</span>
            <br />
            <span>Cód: {product.product_code}</span>
          </div>}
        />
        <div className={"item-btn-confirm " + (selected !== undefined && (product.id === selected.id) ? "item-btn-confirm-selected" : "")}>
          <CheckCircleOutlined />
        </div>
      </List.Item>
    </TouchableOpacity >
  }
  const onScroll = (event) => {
    var target = event.target
    if (!loading && target.scrollTop + target.offsetHeight >= target.scrollHeight) {
      loadMore();
    }
  }

  return <Modal
    title={"Seleção de produto"}
    className="modal-product-select"
    centered
    maskClosable={false}
    cancelText={'Cancelar'}
    visible={props.visible}
    onOk={onHandleClick}
    onCancel={onCancel}
  >
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',



    }}>
      <Radio.Group
        size={'medium'}
        buttonStyle="solid"
        value={tab}
        onChange={onChangeTab}
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          flexGrow: 2,
        }}>
        {suggestions.length > 0 && <Radio.Button value="suggestions" style={{
          textAlign: 'center',
        }}>Sugestões ({suggestions.length})</Radio.Button>}
        <Radio.Button value="all" style={{
          textAlign: 'center',
        }}>Todos</Radio.Button>

      </Radio.Group>
      {tab === 'all' && loading && <Spin style={{
        margin: '0 7%',
        alignSelf: 'center',
      }} />}
    </div>

    <Form {...formItemLayout}>
      <div style={{
        margin: '10px 10px'
      }}>
        <Form.Item label="Pesquisa: " htmlFor={'search'}>
          {getFieldDecorator('search', {})(
            <Input
              onChange={e => setSearch(e.target.value)}
              placeholder={'Comece a digitar para pesquisar'} />
          )}
        </Form.Item>
      </div>
    </Form>


    <div onScroll={onScroll}>
      <List className="list-items-search"
      >
        {tab === 'suggestions' ? filteredSuggestions.map((product, index) => renderProduct({ ...product, id: index })) : products.map(product => renderProduct(product))}
      </List>
    </div>
  </Modal>
}
const ModalProductSelectForm = Form.create({
  name: 'modal_product_select'
})(ModalProductSelect);

export default ModalProductSelectForm;
