import React, {Component} from 'react';
import './App.css';
import {Layout} from 'antd';
import Page404 from "./Components/Page404";

const {Header, Content} = Layout;

export default class PageNotFound extends Component {

	render() {
		return (
			<Layout className="layout">
				<Header className="header">
					<div>
						<h1>Pedido EDI</h1>
					</div>
				</Header>
				<Content>
					<Page404 />
				</Content>
			</Layout>
		);
	}
}
