import React from 'react';
import {color} from './../common';

import {
  FloatingMenu,
  MainButton,
} from 'react-floating-button-menu';
import {TouchableOpacity} from "react-native-web";

const FloatingButton = ({action, icon}) => {

  return (
    <TouchableOpacity style={{
      right: 0,
      bottom: 20,
      position: 'fixed',
      margin: '20px',
    }}>
      <FloatingMenu
        slideSpeed={500}
        spacing={8}
      >
        {
          [
            <MainButton
              key={1}
              background={color.primaryColor}
              iconResting={icon}
              iconActive={icon}
              onClick={action}
              size={56}
            />
          ]
        }
      </FloatingMenu>
    </TouchableOpacity>
  );
}

export default FloatingButton;
