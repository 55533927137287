import React, {useState} from "react";
import ModalImageInfo from "./ModalImageInfo";
import {Icon as LegacyIcon} from "@ant-design/compatible";


const IconText = ({type, text, color}) => (
  <span>
        <LegacyIcon type={type} style={{marginRight: 8, color: color}}/>
    {text}
    </span>
)

const ExtraInfoCustomerNotFound = ({item, customer_info, containerStyle}) => {
  const [showModalInfoImage, setShowModalInfoImage] = useState(false);
  const [currentIdImage, setCurrentIdImage] = useState(null);
  const [currentInfoImage, setCurrentInfoImage] = useState(null);

  function getMessageCustomerNoFound(item) {
    return `O CNPJ 0 constante no pedido Nº. ${item.reference} na pág ${item.initial_page} deste PDF, não foi encontrado na sua base de clientes.`;
  }

  const infos = [
    {
      label: 'Loja',
      image_id: 'id_img_id_loja',
      field: 'id_loja',
    },
    {
      label: 'Endereço',
      image_id: 'id_img_endereco_loja',
      field: 'endereco_loja',
    },
    {
      label: 'Telefone',
      image_id: 'id_img_telefone_loja',
      field: 'telefone_loja',
    },
    {
      label: 'CNPJ',
      image_id: 'id_img_cnpj_loja',
      field: 'cnpj_loja',
    }
  ]

  return (
    <>
      <span style={{...containerStyle}}>
        {
          customer_info ?
            <div>
              <IconText
                type="info-circle"
                text={'Informações encontradas:'} />

              {
                infos.map((info)=> {
                  if (!customer_info[info.field]) {
                    return null;
                  }

                  return (
                    <div key={info.field}>
            <span style={{
              fontWeight: '600',
              fontSize: '15px',
              marginRight: 5,
            }} onClick={()=> {
              setShowModalInfoImage(true);
              setCurrentInfoImage(info);
              setCurrentIdImage(customer_info[info.image_id]);
            }}>
              {info.label}:
            </span>
                      <span style={{
                        fontWeight: '500',
                        fontSize: 12,
                      }}>{customer_info[info.field]}</span>
                    </div>
                  )
                })
              }
            </div>
            :
            <IconText
              type="info-circle"
              text={item.invalid_reason || getMessageCustomerNoFound(item)} />
        }
      </span>

      {
        currentIdImage && (
          <ModalImageInfo
            id_image={currentIdImage}
            title={currentInfoImage.label}
            visible={showModalInfoImage}
            onCancelModal={()=> {
              setShowModalInfoImage(false);
            }}
          />
        )
      }
    </>
  )

}

export default ExtraInfoCustomerNotFound;
