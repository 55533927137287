import _ from 'lodash';
import getApi from './index';
import { ORDER_STATUS } from "../Util";
import Axios from 'axios';

export async function list({ page = 1, status_filter = 'all', cancelToken }) {
  const promise = await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();

      let value_for_status;

      if (status_filter === 'revised') {
        value_for_status = 'revised,sent_to_device';
      } else if (status_filter === 'pending') {
        value_for_status = 'created,viewed,client_not_found';
      } else {
        value_for_status = '';
      }

      const response = await api.get(`/order/?page=${page}&status=${value_for_status}`, { cancelToken });
      const { status, data } = response;

      if (status >= 200 && status <= 300) {

        const results = data.results.map((result) => {
          const page_pdf = _.get(result, 'original_content.pagina_inicial', 0) + 1;
          const pdf_id = _.get(result, 'original_content.pdf_id');
          const customer_info = _.get(result, 'original_content.informacao_cliente');

          return {
            ...result.parsed_content,
            reference: result.reference,
            invalid_reason: result.invalid_reason,
            customer_info: customer_info,
            id: result.id,
            status: result.status,
            created_at: result.created_at,
            modified_at: result.modified_at,
            order_number: result.order_number,
            temp_order_number: result.temp_order_number,
            initial_page: page_pdf,
            pdf_id: pdf_id,
            attempt_quantity_export: (result.attempt_revised_content || []).length,
            exists_in_other_order: result.exists_in_other_order,
            duplicate_reference: result.duplicate_reference,
            observacao: result.observacao,
            status_erp: result.status_erp
          };
        });

        resolve({
          ...data,
          page: page + 1,
          results
        });
      } else {
        reject(new Error('Não foi possível enviar o pedido!'));
      }
    } catch (e) {
      if(Axios.isCancel(e)) {
        console.log('canceled request');
      } else {
        reject(new Error(e));
      }
    }
  });

  return promise;
}

export async function save(id, payload) {
  return await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.post(`/order/${id}/save/`, payload);
      const { status, data } = response;

      if (status >= 200 && status <= 300) {
        resolve(data);
      } else {
        reject(new Error('Não foi possível salvar o pedido!'));
      }
    } catch (e) {
      reject(new Error(e));
    }
  });
}

export async function cancel(id) {
  return await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.post(`/order/${id}/cancel/`);
      const { status, data } = response;

      if (status >= 200 && status <= 300) {
        resolve(data);
      } else {
        reject(new Error('Não foi possível cancelar o pedido!'));
      }
    } catch (e) {
      reject(new Error(e));
    }
  });
}

export async function confirm(id, payload) {
  const promise = await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.post(`/order/${id}/confirm/`, payload);
      const { status, data } = response;

      if (status >= 200 && status <= 300) {
        resolve(data);
      } else {
        reject(new Error('Não foi possível enviar o pedido!'));
      }
    } catch (e) {
      reject(new Error(e));
    }
  });

  return promise;
}

export async function sendToDevice(id, payload) {
  const promise = await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.post(`/order/${id}/send-to-device/`, payload);
      const { status, data } = response;

      if (status >= 200 && status <= 300) {
        resolve(data);
      } else {
        reject(new Error('Não foi possível enviar o pedido!'));
      }
    } catch (e) {
      reject(new Error(e));
    }
  });

  return promise;
}

export default async function detail(id, visual_decimal_places) {
  const promise = await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.get(`/order/${id}/`);
      const { status, data } = response;

      if (status >= 200 && status <= 300) {
        const page = _.get(data, 'original_content.pagina_inicial', 0) + 1;
        const pdf_id = _.get(data, 'original_content.pdf_id');

        let order = {
          ...data,
          page: page,
          pdf_id: pdf_id,
        };

        if ((order.status === 'revised' || order.status === ORDER_STATUS.SENT_TO_DEVICE)
          && !!order.temp_order_number) {
          const final_content = data.final_content || {};
          const final_products = final_content.produtos.map((item) => {
            const product = _.find(data.revised_content.products, { product_code: item.codigo });
            const parsedProduct = _.find(data.products, { product_code: item.codigo });

            return {
              ...parsedProduct,
              unit_value: parseFloat(item.preco || 0).toFixed(visual_decimal_places),
              quantity: product.quantity,
              total_value: parseFloat(item.precototal || 0).toFixed(visual_decimal_places),
            }
          });

          order = {
            ...order,
            amount: parseFloat(final_content.total || 0).toFixed(visual_decimal_places),
            products: final_products,
            deleted_items: _.differenceBy(data.products, final_products, 'index'),
          }
        }

        resolve(order);
      } else {
        reject(new Error('Não foi possível obter os detalhes do pedido!'));
      }
    } catch (e) {
      reject(new Error('Não foi possível obter os detalhes do pedido!'));
    }
  });

  return promise;
}

export async function getSuggestedClients(id, query = '') {
  const promise = await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.get(`/order/${id}/suggested-clients/?q=${query}`);
      const { status, data } = response;

      if (status >= 200 && status <= 300) {
        resolve(data.results);
      } else {
        reject(new Error('Não foi possível obter as sugestões de clientes!'));
      }
    } catch (e) {
      reject(new Error('Não foi possível obter as sugestões de clientes!'));
    }
  });

  return promise;
}

export async function getSuggestedProducts(id, query = '', page = 1) {
  const promise = await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.get(`/order/${id}/suggested-products/?q=${query}&page=${page}`);
      const { status, data } = response;

      if (status >= 200 && status <= 300) {
        resolve(data);
      } else {
        reject(new Error('Não foi possível obter as sugestões de produtos!'));
      }
    } catch (e) {
      reject(new Error('Não foi possível obter as sugestões de produtos!'));
    }
  });

  return promise;
}

export async function setCustomer(id, payload) {
  return await new Promise(async (resolve, reject) => {
    try {
      const api = await getApi();
      const response = await api.post(`/order/${id}/set-client/`, payload);
      const { status, data } = response;

      if (status >= 200 && status <= 300) {
        resolve(data);
      } else {
        reject(new Error('Não foi possível definir o cliente do pedido!'));
      }
    } catch (e) {
      reject(new Error(e));
    }
  });
}
