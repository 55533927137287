import React, {Component} from 'react';
import {PageHeader, Dropdown, Menu} from 'antd';
import {LeftOutlined, MenuOutlined} from "@ant-design/icons";
import style from './styles';
import Profile from '../../Screens/Profile'

const menu = (
  <Menu>
    <Menu.Item>
      <a href="/#/">
        Pedidos
      </a>
    </Menu.Item>
    <Menu.Item>
      <a href="/#/documents">
        Documentos
      </a>
    </Menu.Item>
  </Menu>
);

class SubTitle extends Component {

  onBack = () => {
    if (this.props.onBack) {
      this.props.onBack();
    } else {
      window.history.back();
    }
  };

  render() {
    const { title, hide_icon_back } = this.props;

    return (
      <PageHeader
        extra={[
          <Profile />,
          <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow>
            <MenuOutlined style={{ color: '#fff', fontSize: '20px' }} />
          </Dropdown>
        ]}
        style={style.page_header}
        className="site-page-header"
        backIcon={
          !hide_icon_back && <LeftOutlined style={style.icon_back} />
        }
        onBack={this.onBack}
        title={<h4 style={style.title}>{ title }</h4>}
      />
    );
  }
}

export default SubTitle;
