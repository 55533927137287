import React from "react";
import { Empty } from 'antd';

export default class EmptyView extends React.Component {

	render() {

		return (
			<div style={{marginBottom: '30px'}}>
				<Empty description={<span>Não há itens na situação atual.</span>}>
				</Empty>
			</div>
		)
	}

}
