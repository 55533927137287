import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import './../App.css';
import SkeletonLoading from './../Components/SkeletonLoading';
import {ArrowLeftOutlined, UpCircleOutlined} from '@ant-design/icons';
import {Alert, BackTop, Button, Layout, Typography} from 'antd';
import Order from './../Services/Order';
import {withRouter} from 'react-router-dom';
import profileState from './../Stores/Atoms/ProfileAtom';
import {useRecoilState} from 'recoil';
import ImageLoader from "react-load-image";
import {formatBRL} from "../Util/Number";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import {ORDER_STATUS} from "../Util";

const {Header, Content} = Layout;
const {Title} = Typography;

const style = {
  width: 40,
  height: 40,
  lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#1088e9',
  color: '#fff',
  textAlign: 'center',
  fontSize: 14,
};

const LENGHT = 20;

const DetailOrder = (props) => {
  const [profile, setProfile] = useRecoilState(profileState);
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState({})
  const [fetched, setFetched] = useState(false)
  const [error, setError] = useState(null)
  const [condition, setCondition] = useState(null)
  const [has_more, setHasMore] = useState(true)
  const [products, setProducts] = useState([])
  const [items, setItems] = useState([])
  const {settings} = profile;
  const {visual_decimal_places} = settings;
  const [deletedItems, setDeletedItems] = useState([]);

  useEffect(() => {
    loadOrder();
  }, []);

  const fetchMoreData = () => {
    if (products.length >= items.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      let max = (products.length + LENGHT) > items.length ? items.length : products.length + LENGHT;

      let prods = [...products];
      for (let i = prods.length; i < max; i++) {
        prods.push(items[i]);
      }

      setProducts([...prods]);
    }, 500);
  }

  const loadOrder = async () => {
    try {
      setLoading(true);
      const {id} = props.match.params;
      const order = await Order(id, visual_decimal_places)

      if (!order.reference ||
        (order.status !== ORDER_STATUS.SENT_TO_DEVICE && order.status !== ORDER_STATUS.REVISED) ||
        !order.temp_order_number) {
        props.history.push(`/`);
      }

      const conditions = order.conditions || [];
      const cond = _.find(conditions, {'code': order.condition}).value.split(' | ');
      setCondition({
        org_venda: cond[0],
        meio: cond[1],
        prazo: cond[2],
        tabela_preco: cond[3],
        tipo_venda: cond[4],
      });

      setOrder({
        ...order
      });
      setFetched(true);

      const all_items = _.orderBy(_.get(order, 'products', []), 'name');
      let max = all_items.length < LENGHT ? all_items.length : LENGHT;
      if (all_items.length < LENGHT) {
        setHasMore(false);
      }

      let prods = [];
      for (let i = 0; i < max; i++) {
        prods.push(all_items[i]);
      }

      setProducts([...prods]);
      setItems([...all_items]);
      setDeletedItems([...order.deleted_items || []]);
    } catch (e) {
      setFetched(false);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const renderItem = (item, index, showPriceProcessed = true) => {
    const unitValue = parseFloat(item.unit_value || 0).toFixed(visual_decimal_places);
    const totalValue = parseFloat(item.total_value || 0).toFixed(visual_decimal_places);

    const originalUnitValue = parseFloat(item.original_unit_value || 0).toFixed(visual_decimal_places);
    const originalTotalValue = parseFloat(item.original_total_value || 0).toFixed(visual_decimal_places);

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        background: '#FFF',
        borderBottom: '1px solid #d9d9d9',
        marginBottom: 5,
        alignItems: 'center',
        borderRadius: '5%',
      }} key={index.toString()}>
        <div style={{
          width: '80px',
          height: '130px',
          margin: 10,
        }}>
          <ImageLoader src={item.image}>
            <img alt='' style={{
              width: '70px',
              height: '110px',
            }}/>
            <img src='../../assets/no-image.png' alt='' style={{
              width: '80px',
              height: '130px',
            }}/>
          </ImageLoader>
        </div>
        <div className="display-flex-column">
          <span style={{
            fontSize: 16,
            fontWeight: 700,
          }}>{item.name}</span>
          <div className="display-flex-row">
            <span>CÓDIGO:</span>
            <span style={{
              marginLeft: 10,
            }}>{item.product_code}</span>
          </div>
          <div className="display-flex-row">
            <span>EAN:</span>
            <span style={{
              marginLeft: 10,
            }}>{item.ean}</span>
          </div>

          {
            item.original_unit_value && (
              <div className="display-flex-row fw-600">
                <span style={{
                  fontSize: 12,
                }}>
                  PREÇO IMPORTADO: {formatBRL(originalUnitValue, visual_decimal_places)}&nbsp;X&nbsp;{item.original_quantity}&nbsp;=&nbsp;{formatBRL(originalTotalValue, visual_decimal_places)}
                </span>
              </div>
            )
          }

          {
            showPriceProcessed && (
              <div className="display-flex-row fw-600">
                <span style={{
                  fontSize: 12,
                }}>
                  PREÇO PROCESSADO:&nbsp;{formatBRL(unitValue, visual_decimal_places)}&nbsp;X&nbsp;{item.quantity}&nbsp;=&nbsp;{formatBRL(totalValue, visual_decimal_places)}
                </span>
              </div>
            )
          }
        </div>
      </div>
    )
  }

  const {customer} = order || {};

  return (
    <Layout>
      <Header className="header">
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          marginLeft: '-30px'
        }}>
          <ArrowLeftOutlined
            onClick={() => {
              props.history.push(`/`)
            }}
            style={{color: '#fff'}}
            width={'4em'}
            height={'4em'}/>
          <h1 style={{
            marginLeft: 10
          }}>
            {'Detalhe do pedido'}
          </h1>
        </div>
      </Header>
      <Content style={{
        margin: 20,
        marginRight: 10,
      }}>
        <SkeletonLoading loading={loading}/>

        {
          error && (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Alert
                description={error}
                type="error"
                showIcon
              />
              <Button type="primary" style={{
                marginTop: 10,
              }} onClick={() => {
                setFetched(false);
                setError(null);

                setTimeout(loadOrder, 500);
              }}>
                Atualizar
              </Button>
            </div>
          )
        }

        {
          !error && fetched && (
            <div>
              <h1>{`#${order.reference}`}</h1>

              <Title level={4}>Resumo</Title>

              <div className="display-flex-column" style={{
                background: '#FFF',
                padding: 10,
                borderRadius: '5%',
                marginBottom: 10,
              }}>
                <span className="fw-600">{customer.code} - {customer.name}</span>
                <span>
                  <span style={{
                    fontWeight: '500',
                  }}>CNPJ:</span> {customer.nin}</span>

                <div style={{
                  marginBottom: 10,
                  marginTop: 5,
                  background: '#CCC',
                }}>

                </div>

                <div className="display-flex-row" style={{
                  justifyContent: 'space-between'
                }}>
                  <div className="display-flex-column">
                    <span style={{
                      fontWeight: '600',
                    }}>Número Polibras </span>
                    <span>
                        {order.order_number}
                    </span>
                  </div>

                  <div className="display-flex-column">
                    <span style={{
                      fontWeight: '600',
                    }}>Página</span>
                    <span>
                      {order.page}
                    </span>
                  </div>

                  <div className="display-flex-column">
                    <span style={{
                      fontWeight: '600',
                    }}>PDF ID</span>
                    <span>
                      {order.pdf_id}
                    </span>
                  </div>
                </div>

                <div className="display-flex-row" style={{
                  justifyContent: 'space-between'
                }}>
                  <div className="display-flex-column">
                    <span style={{
                      fontWeight: '600',
                    }}>Data </span>
                    <span>
                      {moment(order.created_at).format('DD/MM/YYYY HH:mm')}
                    </span>
                  </div>

                  <div className="display-flex-column" style={{
                    marginLeft: -20
                  }}>
                    <span style={{
                      fontWeight: '600',
                    }}>Total </span>
                    <span>
                      {formatBRL(order.amount, visual_decimal_places)}
                    </span>
                  </div>

                  <div></div>
                </div>

                <div className="display-flex-row" style={{
                  justifyContent: 'space-between',
                  marginTop: 10
                }}>
                  <div className="display-flex-column">
                    <span style={{
                      fontWeight: '600',
                    }}>Últ. movimentação </span>
                    <span>
                      {moment(order.modified_at).format('DD/MM/YYYY HH:mm')}
                    </span>
                  </div>
                </div>
                <div className="display-flex-row" style={{
                  marginTop: 10
                }}>
                  <div className="display-flex-column">
                    <span style={{
                      fontWeight: '600',
                    }}>Status</span>
                    <span style={{
                      fontWeight: '600',
                      color: '#52c41a'
                    }}>
                      {order.status_erp}
                    </span>
                  </div>
                </div>
                <div className="display-flex-row" style={{
                  marginTop: 10
                }}>
                  {Boolean(order.observacao) && <div className="display-flex-column">
                    <span style={{
                      fontWeight: '600',
                    }}>Observação</span>
                    <span>
                      {order.observacao}
                    </span>
                  </div>}
                </div>
              </div>

              <Title level={4}>Condições de venda</Title>

              <div className="display-flex-column" style={{
                background: '#FFF',
                padding: 10,
                borderRadius: '5%',
                marginBottom: 10,
              }}>
                <div>
                  <span style={{
                    fontWeight: '600',
                    marginRight: 10,
                  }}>Organização de venda:</span>
                  <span>{condition.org_venda}</span>
                </div>
                <div>
                  <span style={{
                    fontWeight: '600',
                    marginRight: 10,
                  }}>Tipo de venda:</span>
                  <span>{condition.tipo_venda}</span>
                </div>
                <div>
                  <span style={{
                    fontWeight: '600',
                    marginRight: 10,
                  }}>Tabela:</span>
                  <span>{condition.tabela_preco}</span>
                </div>
                <div>
                  <span style={{
                    fontWeight: '600',
                    marginRight: 10,
                  }}>Meio:</span>
                  <span>{condition.meio}</span>
                </div>
                <div>
                  <span style={{
                    fontWeight: '600',
                    marginRight: 10,
                  }}>Prazo:</span>
                  <span>{condition.prazo}</span>
                </div>
              </div>

              <Title level={4}>Itens ({items.length})</Title>

              <InfiniteScroll
                dataLength={products.length}
                next={fetchMoreData}
                hasMore={has_more}
                scrollThreshold={0.9}
                loader={<h4>Carregando...</h4>}
              >
                {
                  products.map((item, index) => {
                    return renderItem(item, index)
                  })
                }
              </InfiniteScroll>

              {
                !!deletedItems.length && (
                  <>
                    <Title level={4}>Itens Descartados ({deletedItems.length})</Title>

                    {
                      deletedItems.map((item, index) => {
                        return renderItem(item, index, false)
                      })
                    }
                  </>
                )
              }
            </div>
          )
        }

        <BackTop>
          <UpCircleOutlined height={60} width={60} style={style}/>
        </BackTop>
      </Content>
    </Layout>
  );
}

export default withRouter(DetailOrder)
