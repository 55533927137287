import React, { useState } from 'react'
import { CaretDownOutlined, FormOutlined } from '@ant-design/icons';
import ModalSaleConditions from "./ModalSaleConditions";

const styles = {
  container: {
    backgroundColor: '#01386A',
    marginTop: 1,
    color: '#fff',
    padding: 10,
    cursor: 'pointer'
  },
  title: {
    fontWeight: '600'
  }
};

const SaleConditions = (props) => {

  const [visible, setVisible] = useState(false)

  const openModal = () => {
    setVisible(!visible);
  };

  const onSelect = (selected_item) => {
    openModal();
    props.handleChangeSaleCondition(selected_item);
  };

  const { items, itemSelected, selected } = props;

  return (
    <div>

      {
        itemSelected && (
          <ModalSaleConditions
            selected={selected}
            itemSelected={itemSelected}
            onSelect={onSelect}
            onCancel={openModal}
            visible={visible}
            items={items} />
        )
      }

      {
        items.length ?
          <div style={styles.container} onClick={openModal}>
            <FormOutlined
              size={'large'}
              style={{
                color: '#fff', width: 28, height: 20
              }} />
            <span>Condição de venda: </span>
            <span style={styles.title}>
              {itemSelected ? itemSelected.value : 'Escolha as condições de venda'}
            </span>
            <CaretDownOutlined size={'large'} style={{ color: '#fff' }} />
          </div>
          :
          <div style={styles.container}>
            <FormOutlined
              size={'large'}
              style={{
                color: '#fff', width: 28, height: 20
              }} />
            <span>Condição de venda: </span>
            <span style={styles.title}>Nenhuma condição encontrada</span>
            <CaretDownOutlined size={'large'} style={{ color: '#fff' }} />
          </div>
      }
    </div>
  );
}

export default SaleConditions;
