import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native-web'
import { Badge } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DownOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'
import moment from 'moment'
import { color } from '../../common'

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 16,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: '#074885',
    fontSize: 12,
    fontWeight: '600',
  },
  iconContainer: {
    width: 35,
    height: 35,
    borderRadius: 25,
    backgroundColor: color.primaryColorLightest,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  icon: {
    width: 24,
    height: 24,
    tintColor: color.primaryColorLight,
  },
  badgeContainer: {
    width: 22,
    height: 22,
    borderRadius: 12,
    backgroundColor: color.grayContainerDark,
    justifyContent: 'center',
    alignItems: 'center',
  },
  greenBadgeContainer: {
    width: 16,
    height: 16,
    borderRadius: 12,
    backgroundColor: '#FFC400', // '#0EAF31',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBadgeCheck: {
    width: 18,
    height: 18,
    tintColor: '#fff',
  },
}

const DocumentItem = ({ item, onPress }) => {

  const getItemStatus = () => {
    const { status } = item;
    let checkStatus = status;

    switch (checkStatus) {
      case 'created':
        return {
          label: 'Em processamento',
          icon: <ClockCircleOutlined style={{
            backgroundColor: '#FFC400',
            color: '#fff',
            fontSize: 20,
            borderRadius: '50%'
          }}/>
        };
      case 'error':
        return {
          label: 'Falha',
          icon: <CloseCircleOutlined style={{
            backgroundColor: '#f10505',
            color: '#fff',
            fontSize: 20,
            borderRadius: '50%'
          }}/>
        };
      case 'processing':
        return {
          label: 'Em processamento',
          icon: <ClockCircleOutlined style={{
            backgroundColor: '#FFC400',
            color: '#fff',
            fontSize: 20,
            borderRadius: '50%'
          }}/>
        };
      case 'done':
        return {
          label: 'Processado',
          icon:  <CheckCircleOutlined
            style={{
              backgroundColor: '#52c41a',
              color: '#fff',
              fontSize: 20,
              borderRadius: '50%'
            }}
          />
        };
      default:
        return null;
    }
  }

  const getFileName = () => {
    const url = item.document_file;
    return url ? url.substring(url.lastIndexOf('/') + 1) : item.identifier;
  }

  const status_item = getItemStatus();

  return (
    <TouchableOpacity
      onPress={onPress}
    >
      <View style={styles.container}>
        <View style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'start',
        }}>
          <View style={{
            marginRight: 5
          }}>
            <Badge count={status_item.icon}/>
          </View>
          <View style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
          }}>
            <View style={{
              marginLeft: '10px',
              marginTop: 10
            }}>
              <Text style={{
                fontSize: '12px',
                fontWeight: '600',
              }}>{status_item.label}</Text>
              <Text style={{
                fontSize: '10px',
                fontWeight: '300'
              }}>{moment(item.created_at).format('LT')}</Text>
            </View>
          </View>
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}>
            <View style={{
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px'
            }}>
              <Text style={styles.title}>{`${getFileName()} (${item.count_orders} PEDIDOS)`}</Text>
            </View>
          </View>
        </View>
        <View style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}}>
          {Boolean(item.pdf_id) && ( 
              <div>
                  <span style={{fontWeight: 600}}> PDF ID: </span>
                  <span> {item.pdf_id} </span>
              </div>
            )}
          <DownOutlined/>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default DocumentItem
