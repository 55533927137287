export const money = (value, n, x, s, c) => {
  let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = parseFloat(value).toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

export const formatBRL = (value, numer_decimal = 2) => {
  value = parseFloat(value);
  return 'R$ ' + money(value, numer_decimal, 3, '.', ',');
};

export const toMoney = (value) => {
  return money(value, 2, 3, '.', ',')
};

export const toNumber = (value) => {
  return value.toString().replace('.', '').replace(',', '.');
};
