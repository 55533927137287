import React from "react";
import { Result} from 'antd';

export default class Page404 extends React.Component {

	refresh() {
		window.location.reload();
	}

	render() {

		return (
			<div>
				<Result
					status="404"
					title="Página não encontrada!"
					subTitle="Desculpe, essa página não está disponível!"
					extra={[
						<p key={0} style={{
							fontWeight: '700', fontSize: '16px'
						}}>Solicite um novo link para acessar a funcionalidade!</p>
					]}
				/>
			</div>
		)
	}

}
