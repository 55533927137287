export default {
  primaryColor: '#074885',
  primaryColorLight: '#0a7bd6',
  primaryColorLightest: '#b1ddf6',
  tabBarBackgroundColor: '#fff',
  primaryColorDarkest: '#04294b',
  primaryColorDark: '#053867',
  accentColor: '#1CBC26',
  primaryTextColor: '#105EB8',
  primaryColorTable: '#F4F9FF',
  primaryColorGray: '#A0B0C3',
  accentColorLight: '#9cffde',
  accentColorLightest: '#cdffec',
  successColor: '#1CBC26',
  successColorLight: '#d1ff88',
  successColorLightest: '#f4ffe4',
  blueLightest: '#b6e5ff',
  greenLightest: '#e6ffcc',
  grayRed: '#ae2029',
  dangerColor: '#cc0007',
  dangerColorDark: '#760004',
  dangerColorDarkest: '#3a0002',
  dangerColorLight: '#ff8d98',
  dangerColorLightest: '#ffd9e7',
  grayRedDark: '#8d343a',
  grayTextInput: '#7d7d7d',
  grayContainer: '#F0F0F0',
  grayContainerDark: '#dcdcdc',
  grayDark: '#7a7a7a',
  grayDarkest: '#414141',
  grayMid: '#969696',
  gray: '#D8D8D8',
  grayAlmostWhite: '#e7e7e7',
  navBarButtonColor: '#fff',
  // tabBarSelectedTextColor: '#03CEA4',
  tabBarSelectedTextColor: '#fda400',
  separatorList: '#d8e1e1',
  blueBadge: '#00a4ff',
  blueIOS: '#157efb',
  redBadge: '#df0800',
  redLight: '#FF6D6D',
  redLightest: '#ffacb1',
  yellowVibrant: '#ffb318',
  yellowBadge: '#df9900',
  warningColor: '#ff9600',
  warningColorDark: '#bc6f00',
  warningColorDarkest: '#814c00',
  warningColorLight: '#ffab59',
  warningColorLightest: '#ffdebe',
  facebookBlue: '#4267b2',
  googleRed: '#db4437',
  graySearchContainer: '#dcdce1',
  stripGreen: '#7dc736',
  stripGreenLightest: '#e7ffd0',
  whatsappGreen: '#075E54',
  whatsappGreenLight: '#25D366',
  stripOrange: '#c76e00',
  infoAlert: '#FF7E00',
  stripGreenDark: '#5f9f2d',
  stripBlue: '#3ba1c1',
  navBarSubtitleColor: '#fff',
  textEmpty: '#8cc5da',
  sectionIOS: '#6D6D72',
};
