import React from 'react'
import {Button, Modal, Progress, Spin} from 'antd'
import {Text, View} from 'react-native-web'
import {color} from '../../common'
import {CloseCircleOutlined, LoadingOutlined} from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

const ModalSendPDF = ({
                        show_modal,
                        setShowModal,
                        document_selected,
                        setDocumentSelected,
                        onHandleSendFile,
                        progressFile,
                        processingPdf,
                      }) => {

  return (
    <>
      <Modal
        closable={!progressFile}
        visible={show_modal}
        title="Selecione o PDF"
        maskClosable={false}
        onCancel={() => {
          setShowModal(false)
          setDocumentSelected(null)
        }}
        footer={[
          <Button key="back"
                  disabled={processingPdf}
                  onClick={() => {
                    setDocumentSelected(null)
                    setShowModal(false)
                  }}>
            Cancelar
          </Button>,
          <Button
            disabled={processingPdf}
            key="submit"
            type="primary" onClick={() => {
            onHandleSendFile()
          }}>
            Enviar
          </Button>,
        ]}
      >
        {
          processingPdf && (progressFile != 100) && (
            <Progress percent={progressFile}
                      strokeColor={'#04F604'}
            />
          )
        }

        {
          processingPdf && progressFile == 100 && (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 10,
            }}>
              <Spin indicator={antIcon}/>
            </div>
          )
        }

        {
          !document_selected ?
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}>
              <input type="file" name="file" id="file-5"
                     accept="application/pdf"
                     onChange={(ev) => {
                       setDocumentSelected(ev.target.files[0])
                     }}
                     className="inputfile inputfile-4"/>
              <label htmlFor="file-5">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                    <path
                      d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                  </svg>
                </figure>
                <span>Selecionar PDF&hellip;</span></label>
            </View>
            :
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 5,
              border: `1px solid ${color.primaryColor}`
            }}>
              <Text style={{
                fontSize: '14px',
                fontWeight: '600'
              }}> {document_selected.name} </Text>

              {
                !processingPdf && (
                  <CloseCircleOutlined
                    onClick={() => {
                      setDocumentSelected(null)
                    }}
                    style={{
                      fontSize: '22px',
                      color: '#fff',
                      borderRadius: '50%',
                      backgroundColor: color.primaryColor,
                    }}/>
                )
              }
            </View>
        }
      </Modal>
    </>
  )
}

export default ModalSendPDF

